import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material';
import { LocalStorage } from 'app/shared/object-storage';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
    language: string;

    constructor(private translate: TranslateService) {
        this.language = LocalStorage.getItem('language', 'nl');
        this.translate.use(this.language);
    }


    changeLanguage($event: MatSelectChange) {
        this.translate.use($event.value);

        LocalStorage.setItem('language', $event.value);
    }

    ngOnInit() {
    }

}
