import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/internal/operators/take';
import { environment } from 'environments/environment';

export enum AnalyticsPages {
    HOMEPAGE = "Homepage",
    ACTIVATION = "Account activeren",
    FORGOTPASSWORD = "Wachtwoord vergeten",
    CHANGEPASSWORD = "Wachtwoord wijzigen"
}

export interface IAnalyticsEvent {
    e_c: AnalyticsPages;
    e_a: AnalyticsEvents;
    e_n?: string;
}

export enum AnalyticsEvents {
    REQUESTTOKEN = "Token succesvol aangevraagd",
    ACTIVATED = "Account succesvol geactiveerd",
    FORGOTRESET = "Vergeten wachtwoord succesvol gewijzigd",
    BUGERROR = "Er is een bug opgetreden",
    UNKNOWNERROR = "Er is een onbekende fout opgetreden",
    DEFAULTERROR = "Er is een default fout opgetreden",
    TOKENERROR = "Er is een onbekende fout bij token opvragen opgetreden",
    TOKENWAITERROR = "Te veel token requests binnen 1 minuut",
    SETPASSWORDERROR = "Er is een onbekende fout bij wachtwoord zetten opgetreden",
    PASSWORDCHANGED = "Wachtwoord succesvol gewijzigd",
}

@Injectable({
    providedIn: 'root'
})

export class AnalyticsService {

    constructor(private httpClient: HttpClient) { }

    trackPage(action: AnalyticsPages): void {
        if (!environment.analyticsEndpoint) {
            // console.warn("No analytics endpoint configured.");
            return
        }

        this.httpClient.get(
            environment.analyticsEndpoint + '&action_name=' + action, { responseType: 'text' }).pipe(take(1)).subscribe();
    }

    trackEvent(page: AnalyticsPages, event: AnalyticsEvents, errorMessage?: string): void {
        if (!environment.analyticsEndpoint) {
            // console.warn("No analytics endpoint configured.");
            return
        }

        const item: IAnalyticsEvent = {
            e_c: page,
            e_a: event
        }

        if (errorMessage) item.e_n = errorMessage;

        const queryString = Object.keys(item).map(key => key + '=' + item[key]).join('&');

        this.httpClient.get(
            environment.analyticsEndpoint + '&' + encodeURI(queryString), { responseType: 'text' }).pipe(take(1)).subscribe();
    }
}
