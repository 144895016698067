import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { ArtifactService } from './services/artifact.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor() {
        console.log('version: ', environment.app.version);
        console.log('env: ', environment.app.env);
    }



}
