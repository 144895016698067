import { Environment } from 'environments/environment.model';
import { version } from 'environments/../../package.json';

export const environment = <Environment>{
    production: false,
    app: {
        version: version,
        env: 'hva-tst'
    },
    organization: 'hva',
    analyticsEndpoint: '',
    artifactLocation: 'https://appstore.hva.tst.icts.education/assets/apps/'
};
