import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from './loader/loader.component';
import { SwiperModule, SwiperComponent } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NotfoundComponent } from './notfound/notfound.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    spaceBetween: 10,
    speed: 300,
    slideToClickedSlide: true,
    height: 500,
    width: 300,
    centeredSlides: true,
    autoplay: true,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },

};
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forChild(),
        SwiperModule
    ],
    entryComponents: [
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    declarations: [LoaderComponent, NotfoundComponent],
    exports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule, LoaderComponent, NotfoundComponent, SwiperModule, TranslateModule],
})
export class SharedModule { }
