import { NgModule } from '@angular/core';
import {
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    MatListModule,
    MatToolbarModule,
    MatCardModule,
    MatTabsModule
} from '@angular/material';

@NgModule({
    imports: [

    ],
    declarations: [],
    exports: [
        MatStepperModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatBottomSheetModule,
        MatListModule,
        MatToolbarModule,
        MatCardModule,
        MatTabsModule
    ]
})
export class MaterialModule { }
