import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '../../../node_modules/@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        RouterModule,
        SharedModule
    ],
    declarations: [LayoutComponent]
})
export class LayoutModule { }
