import { LayoutComponent } from 'app/layout/layout.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'home', loadChildren: './home/home.module#HomeModule' },
            { path: 'app/:id', loadChildren: './app-detail/app-detail.module#AppDetailModule' },
            { path: 'install/:id', loadChildren: './app-install/app-install.module#AppInstallModule' },
            { path: '**', redirectTo: 'home' }
        ]
    }
];
